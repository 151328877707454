import React from "react"
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import Layout from './layout'

const build_head_from = (pageContext, siteMetadata) => {
  const { baseUri, blogTitle } = siteMetadata

  const head = {
    title: `${pageContext.label} [tag] - ${blogTitle}`,

    link: [
      { rel: "canonical", href: baseUri + pageContext.url },
    ],
  }

  return head
}

export default ({
  data,
  pageContext,
}) => {
  const { site } = data
  const { label, posts } = pageContext

  return <Layout>
    <Helmet {...build_head_from(pageContext, site.siteMetadata)} />

    <section className="posts">
      <header className="post-header">
        <h1 className="post-title"><Link to="/tag/">Tags</Link> - {label}</h1>
      </header>

      <ul className="tag-list fa-ul post">
        { posts.map(post =>
          <li key={post.url} className="mb-5">
            <i className="fa fa-li fa-circle-o fa-sm light-icon" />
            <Link to={post.url}>
              {post.title}
            </Link> <small className="pull-right">{post.date}</small>
          </li>
        ) }
      </ul>
    </section>
  </Layout>
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        blogTitle
        baseUri
      }
    }
  }
`
